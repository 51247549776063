<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col xl="6" sm="12">
        <v-text-field
          label="Buscar histórico por cod. receta"
          background-color="white"
          v-model="id"
          rounded
          filled
          hide-details
          prepend-inner-icon="mdi-magnify"
          append-outer-icon="mdi-send"
          @click:append-outer="searchHistorical"
          @keyup.enter="searchHistorical"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col xl="6" sm="12">
        <v-card v-if="!loading" flat class="rounded-xl">
          <v-card-title>
            <v-row justify="center" class="ma-3">
              <v-chip color="teal lighten-1" outlined dark label>
                <span class="overline">Histórico de la receta</span>
              </v-chip>
            </v-row>
          </v-card-title>
          <v-card-text v-if="computedHistorical.length > 0" class="text-center">
            <v-timeline dense>
              <v-timeline-item
                v-for="(item, i) in computedHistorical"
                :key="i"
                :color="item.color"
              >
                <v-card elevation="0" color="grey lighten-5 ma-2">
                  <v-row>
                    <v-col cols="9" class="text-left">
                      <strong class="ml-5">{{
                        status.filter((x) => x.id === item.status)[0]
                          .description
                      }}</strong>
                      <span v-if="item.message"> | {{ item.message }}</span>
                      <p
                        v-if="item.comment"
                        class="ml-5 mb-0 caption text--secondary"
                      >
                        {{ item.comment }}
                      </p>
                      <div v-if="item.hasDetail" class="mx-5">
                        <div class="overline">DETALLE DE LA RECEPCIÓN:</div>
                        <div>
                          <v-chip class="mx-1"
                            ><strong class="mx-1">ID</strong>
                            {{ item.detail.id }}</v-chip
                          >
                          <v-chip class="mx-1"
                            ><strong class="mx-1">FECHA</strong>
                            {{ formatIncome(item.detail.date) }}</v-chip
                          >
                          <v-chip class="mx-1"
                            ><strong class="mx-1">FARMACIA</strong>
                            {{ item.detail.pharmacy }}</v-chip
                          >
                          <br />
                          <v-simple-table dense class="my-3">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Troquel</th>
                                  <th class="text-left">Plan</th>
                                  <th class="text-left">Cantidad solicitada</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="i in item.detail.items"
                                  :key="i.itemId"
                                >
                                  <td>{{ i.troquel }}</td>
                                  <td>{{ i.plan }}</td>
                                  <td>{{ i.ammount }}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <br />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="3" align-self="center">
                      <v-chip color="teal" dark class="ma-1">
                        <v-icon left> mdi-import</v-icon>
                        <strong> {{ formatIncome(item.income) }}</strong>
                      </v-chip>
                      <v-chip
                        color="teal"
                        outlined
                        class="ma-1"
                        v-if="formatEgress(item.egress)"
                      >
                        <v-icon left> mdi-export</v-icon>
                        <strong> {{ formatEgress(item.egress) }}</strong>
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
          <v-card-text class="pa-5" v-else>
            Escriba un código de receta para ver el histórico
          </v-card-text>
        </v-card>
        <v-card v-else class="pa-5">
          Cargando histórico de la receta...
          <v-progress-linear indeterminate color="teal"></v-progress-linear>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { format, parse, parseISO } from "date-fns";
export default {
  data: () => ({
    id: "C9464732",
    status: [
      {
        id: 2362,
        description: "INGRESO de la receta",
      },
      {
        id: 2363,
        description: "ASIGNACIÓN a farmacia",
      },
      {
        id: 2364,
        description: "ENTREGA de la receta",
      },
      {
        id: 2365,
        description: "Control de ENVIO",
      },
      {
        id: 2366,
        description: "FINALIZADA",
      },
      {
        id: 2367,
        description: "Asignacion Adelantada",
      },
      {
        id: 2368,
        description: "Atencion de Reclamos",
      },
      {
        id: 2369,
        description: "A mesa de entrada",
      },
      {
        id: 2372,
        description: "Responder Reclamo",
      },
      {
        id: 2392,
        description: "Receta con Vale",
      },
      {
        id: 2393,
        description: "Medicacion En Falta",
      },
      {
        id: 2473,
        description: "Modificar Receta",
      },
      {
        id: 2510,
        description: "Entrega Frio",
      },
      {
        id: 2824,
        description: "Entrega Otra Drogueria",
      },
    ],
  }),
  computed: {
    ...mapGetters("historical", ["historical", "loading", "singleLoading"]),

    computedHistorical() {
      if (!this.loading && this.historical)
        return this.historical.map((h) => {
          return {
            ...h,
            ...this.getProperties(h),
          };
        });
      return [];
    },
  },
  methods: {
    ...mapActions("historical", ["findHistorical"]),
    searchHistorical() {
      this.findHistorical({ recipe: this.id });
    },
    formatIncome(date) {
      return format(parseISO(date), "dd/MM/yyyy HH:mm");
    },
    formatEgress(date) {
      if (date === "1984-01-11") return null;
      return format(parseISO(date), "dd/MM/yyyy");
    },
    getProperties(hist) {
      let properties = {};
      if (hist.comment.includes("(WS)"))
        properties = {
          message: "Ingreso desde COFAER",
          color: "amber",
          hasDetail: true,
        };
      else if (this.fromIosper(hist.status))
        properties = {
          message: "Ingreso desde .CSV",
          color: "cyan lighten-1",
        };
      else
        properties = {
          color: "teal",
        };
      return properties;
    },
    fromIosper(st) {
      return st === 2362 || st === 2363 || st === 2364;
    },
  },
};
</script>
